.base{
    min-width: 100%;
    height: 80vh;
    background-size:cover;
    background-position: center;
}

.child-block{
    background-color: rgb(0 0 0  / 32%);
    padding: 20px;
    /* z-index: 2; */
    height: 80vh;
}

.contact-block{
       
    background: linear-gradient(to right, #E6E6FA, #F0F8FF);
}

.div-font {
    font-size: 6rem;
    color: white;
    padding-top: 83px;
}

.input-style{
    border: none;
    outline: none;
    background: transparent;
    border-bottom: 1px solid rgb(155, 155, 155);
    width: 90%;
    margin: 8px 0px;
}

.form-style{
    /* background-color: #f0f0f0; */
    background: linear-gradient(to right, #E6E6FA, #F0F8FF);

    padding:21px
}

.addresses{
    padding-left: 30px;
}

@media screen and (max-width: 800px) {

    .div-font {
        font-size: 4rem;
        margin-top: 40px;
        padding-top: 100px;
    }

}


