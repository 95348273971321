.item {
  /* background-color: #FFF0F5; */
  background: linear-gradient(to right, #fcfcff, #fff9fb);
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.flex-item {
  flex: 0 0 auto;
}
.flex {
  display: flex;
  flex-wrap: nowrap; 
  overflow: auto;
}

.certificate{
  display:flex;
  background: linear-gradient(to bottom, #E6E6FA, #ffffff);
  flex-direction: row;
}
@media screen and (max-width: 990px) {
  .certificate{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.container-flex {
  padding: 5px;
  width: 100%;
  height: auto;
  white-space: initial;
  

} 
.container-flex::-webkit-scrollbar {
  display: none;
}
.container-flex {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}