@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@100;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@1,500&display=swap');

.base{
    min-width: 100%;
    height: 80vh;
    background-size:cover;
    background-position: top;
}

.child-block{
    background-color: rgb(0 57 89 / 55%);
    padding: 20px;
    z-index: 2;
    height: 80vh;
}

.below-block{
    position:relative;
    margin-bottom: 140px;
}

.child-below-block{
    background-color: rgb(255, 255, 255); 
    /* height: 200px; */
    text-align: center;
    top: -30px;
    z-index: 3;
    left: 0;
    right: 0;
    margin: 0px 1.5rem;
    position: absolute;
}

.image2{
    width: 100vw;
    margin-top: 80px;
    
}

.style-text{
    font-family: 'Dancing Script', cursive;
}

.font-style{

    font-family: 'Bitter', serif;
}

.font-style-1{
    font-family: 'Indie Flower', cursive;
}
.font-style-2{
    font-family: 'Exo', sans-serif;
}


.card-color{
    background:linear-gradient(to bottom,rgb(184, 221, 255),rgb(162, 104, 189))
   
}
.card-color1{
    background:linear-gradient(to bottom,rgb(238, 238, 238),rgb(202, 202, 202))
   
}

.vector-size{
    width: 45vw;
}

.service-dis{
    width: 400px;
}

.list-i{
    list-style-type:none;
}



.services-container{
    background-color: rgb(239 239 239)
}
.service-vector{    
    width: 100px;
}

.service{
    list-style-type: none;
}



.vc {
    background: #25b7fd8a;
    color: #353535;
    width: 115px;
    text-align: center;
    padding: 2.5px 18px;
    position: relative;
    box-shadow:#0000001c 7px 4px 15px
  }
  .vc:after {
    width: 0;
    height: 0;
    border: 12.5px solid transparent;
    position: absolute;
    content: "";
    border-top-color: #25b7fd8a;
    right: -23px;
    top: 0px;
    border-left-color: #25b7fd8a;
  }
  .vc:before {
      width: 0;
      height: 0;
      border: 13px solid transparent;
      position: absolute;
      content: "";
      border-bottom-color: #25b7fd8a;
      right: -23px;
      top: -1.5px;
  }



.icon{
    width: 30px;
    /* border: 1px solid grey; */
    /* border-radius: 50px; */
    
    /* padding: px; */

}


.icon-box{
    text-align: center;
  
}

.nav-NavLink{
    text-decoration: none;
    color: grey;
}

.active{
    border-bottom: 1px solid grey;
    padding-bottom: 6px;
}

.color{
    color: #25b7fd;
}



.banner{
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: #00000070;
    z-index: 7;
}

.explore-dis{
    width: 250px;
}

.icon-size{
    width: 70px;

}

.sub-input{
    border: 1px solid white;
    background: transparent;
    padding: 6px;
    border-radius: 9px;
    outline: none;
    color: white;
}
.quote{
    font-size: 1.5rem;
}

.text-service{
    background: linear-gradient(to right, #E6E6FA, #F0F8FF);

    
}

@media screen and (max-width: 800px) {
    .icon-size{
        width: 40px;
    }
    .quote{
        font-size: 1rem;
    }
    /* .below-block{
        display: none;
    } */
    .icon-text{
        font-size: 10px;
    }
    .image2{
        margin-top: 80px;
    }

    .vector-size{
        width: 70vw;
    }
    .vector-present{
      padding: 40px;
    }
  
    .service-dis{
        width: auto
    }
    
  }

  @media screen and (max-width: 990px) {
    .service-dis{
        width: 345px
    }

  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .grid-item {
    min-width: 0; /* Allow the items to shrink below their content width */
  }

  @media screen and (max-width: 800px) {
    .grid-container {
      grid-template-columns: 1fr; /* Change to a single column layout */
    }
    .grid-item:nth-child(1) {
        order: 2; /* Swap the order of the first item with the second */
      }
      .grid-item:nth-child(2) {
        order: 1; /* Swap the order of the second item with the first */
      }

}